import { useState, useContext, useEffect } from "react";
import "./Modal.css";
import { FaCircleChevronLeft } from "react-icons/fa6";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import configURl from "../../runtime.config";
import Form from "react-bootstrap/Form";
import send from "../../images/send.svg";
import { Field, Formik } from "formik";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { IoCloseSharp } from "react-icons/io5";
import PinConfirm from "./PinConfirm";
import { ConversionContext } from "../../Context/context";
import * as Yup from "yup";
import {
  // SendRed,
  transaction,
  getAllClientUsers,
  getConversionRate,
} from "../../services/propertyServices";
import { fireToast, kycErrorPopUp, toastConfirm } from "../../common/Toster";
// import Loader from "../loader/loader";
// import RedTokenAbi from "../../Abis/RedTokenAbi.json";
// import Web3 from "web3";
// import { BigNumber } from "ethers";
// import { Signer, ethers } from "ethers";
// import { getContractInstance } from "../../web3/web3";
import { useAddress, useContract } from "@thirdweb-dev/react";

const Receive = ({ getWallet }) => {
  const contextData = useContext(ConversionContext);
  const isPinActive = contextData?.userData?.isMpinActive;
  const address = useAddress();
  const { contract, isLoading, error } = useContract(configURl.redNewAddress);
  // let userInfo = localStorage.getItem("user-info") || null;
  // userInfo = userInfo ? JSON.parse(userInfo) : null;
  const isKycVerified =
    localStorage.getItem("isKycCompleted") === "true" ? true : false;

  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({});
  const [showPinModal, setShowPinModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [search1, SetSearch] = useState("");
  const [userFullName, SetUserFullName] = useState("");
  const [userData, setUserData] = useState([]);
  const [conversionRate, setConversionRate] = useState(0);

  const handleClosePinModal = () => {
    setShowPinModal(false);
  };

  const handleClose = () => {
    setShow(false);
    SetUserFullName("");
  };
  const handleShow = async () => {
    const exChangeRate = await getConversionRate();
    const { conversionrate } = exChangeRate.data;
    setConversionRate(conversionrate);
    setShow(true);
  };

  const SignupSchema = Yup.object().shape({
    userName: Yup.string().required("User name is required"),
    redToken: Yup.number().required("Red Amount is required").moreThan(0, "Red Amount must be greater than 0"),
    walletAddress: Yup.string()
      .min("42")
      .max("42")
      .required("Address is required"),
  });
  const onFormSubmit = async (data, actions) => {
    toastConfirm(
      "Are you sure?",
      `You want to send ${data.redToken} RED tokens to ${data?.userName ? data?.userName : data?.walletAddress
      }?`
    )
      .fire()
      .then(async (val) => {
        if (val.isConfirmed) {
          (isPinActive
            ? () => {
              setShow(false);
              setFormData(data);
              setShowPinModal(true);
            }
            : () => {
              sendCustomAssets(data);
            })();
          // sendCustomAssets(data);
        }
      });
  };

  // wallet
  const sendCustomAssets = async (data) => {
    try {
      setLoader(true);
      if (isLoading) {
        throw new Error(
          "Please try again after few seconds contract is initalizing !"
        );
      }
      if (error) {
        console.log(error, "contract error");
        throw new Error(
          "Sorry currently we are facing some issue in initalizing contract !"
        );
      }
      // if (
      //   window.web3 &&
      //   window.web3.currentProvider &&
      //   window.web3.currentProvider.isCoinbaseWallet
      // ) {
      //   await window.ethereum.request({
      //     method: "wallet_switchEthereumChain",
      //     params: [
      //       {
      //         chainId: `${"0x89"}`,
      //       },
      //     ],
      //   });
      // }
      // const web3 = (window.web3 = new Web3(window.ethereum));

      // const walletAddress = await window.ethereum.send("eth_requestAccounts");
      // const provider = new ethers.providers.Web3Provider(
      //   window.web3.currentProvider
      // );
      // const signer = provider.getSigner(walletAddress[0]);
      // console.log(signer, "signer");

      // var contractInstance = "";
      // contractInstance = new web3.eth.Contract(
      //   RedTokenAbi,
      //   "0xE3728c6330293B9B2923bd49F9DD31F47d34aaD4"
      // );

      // const newAmount = web3.utils.toHex(
      //   possessions?.propertyDetails?.MonthlyFees * 10 ** 8
      // );

      // const newAmount = (data.redToken * 10 ** 18).toString(); //static value for testing and origional code hide in top
      // const newAmount = BigNumber.from(data.redToken).mul(BigNumber.from(10).pow(18))

      // const instance = await getContractInstance(
      //   redAddress,
      //   RedTokenAbi,
      //   Signer
      // );
      // console.log(instance, "instance123");
      // const tx = {
      //   from: walletAddress[0],
      //   to: contractInstance._address,
      //   data: contractInstance.methods
      //     .transfer(data.walletAddress, newAmount,  { gasLimit: 250000 })
      //     .encodeABI(),
      // };

      // const approveReponse = await instance.transfer(
      //   data?.walletAddress,
      //   newAmount,
      //   {
      //     gasLimit: 300000,
      //   }
      // );

      // web3.eth
      //   .sendTransaction(tx)
      // approveReponse().then((res) => {
      //     getWallet()
      //     setLoader(false);
      //     transaction({walletAddress:data.walletAddress,transactionType:"Red Token",token:data.redToken,userName:data.userName,hashId:res.transactionHash}).then((res)=>{
      //       console.log(res,"res")
      //     })
      //     fireToast("success", "Successfully Sent.");
      //     handleClose();
      //   })
      //   .catch((err) => {
      //     setLoader(false);
      //     console.log("err", err);
      //     fireToast("error", err?.message);
      //   });

      // const response = await approveReponse.wait();
      if (data?.redToken <= 0) {
        throw new Error("Amount must be greater than 0 !");
      }
      const balance = await contract.erc20.balanceOf(address);
      if (+balance?.displayValue < data?.redToken) {
        console.log(error, "balance error");
        throw new Error("Transfer amount exceeds balance !");
      }
      const tx = await contract.erc20.transfer(
        data?.walletAddress,
        data?.redToken
      );
      const response = tx.receipt;
      // console.log(response);
      if (response.status) {
        getWallet();
        setLoader(false);
        transaction({
          walletAddress: data.walletAddress,
          transactionType: "Red Token",
          token: data.redToken,
          userName: data?.userName,
          hashId: response.transactionHash,
        }).then((res) => {
          console.log(res, "res");
        });
        fireToast("success", "Successfully Sent.");
        handleClose();
      }
    } catch (err) {
      fireToast("error", "Something went wrong please try again later !");
      console.log(err, "sendCustomAssets error");
      handleClose();
    } finally {
      setLoader(false);
      handleClose();
    }
  };
  const fetchUserData = async (
    pageNumber = 1,
    pageSize = 5,
    search = search1
  ) => {
    try {
      const list = await getAllClientUsers(pageNumber, pageSize, search);
      if (list.status === 200) {
        setUserData(list?.data?.items);
      } else {
        throw new Error(list.error);
      }
    } catch (err) { }
  };

  useEffect(() => {
    fetchUserData();
  }, [search1]);
  //end wallet

  const handleSearch = (value) => {
    SetSearch(value);
    // console.log(value, "test values");
    fetchUserData();
  };

  return (
    <>
      <Button
        className="bg-transparent border-0 p-0"
        variant="primary"
        onClick={() => {
          // if (isKycVerified) {
          handleShow();
          // } else {
          //   kycErrorPopUp();
          // }
        }}
      >
        <span className="token-btn d-flex align-items-center justify-content-center mx-auto mb-1">
          <img src={send} alt="" />
        </span>
        <p className="scard-btext">Send</p>
      </Button>

      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
        animation={false}
      >
        <Modal.Body>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <span
                className="back-btn fw-500 text-black"
              // onClick={() => handleClose()}
              >
                {/* <i className="pe-2 align-middle">
              <FaCircleChevronLeft />
            </i> */}
                Send RED Token
              </span>
            </div>

            <div>
              <span
                onClick={() => handleClose()}
                style={{ cursor: "pointer", fontSize: "22px" }}
              >
                <IoCloseSharp />
              </span>
            </div>
          </div>

          <div className="dashboard-centerd">
            <div className="row g-0">
              <div className="col-xl-12 col-xxl-12  pt-4  pb-5">
                <div className="">
                  <Tabs
                    defaultActiveKey="platform"
                    id="sendred_tabs"
                    className="marketplace-tabs border-0 mb-4"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Tab eventKey="platform" title="Solos Platform">
                      <div className="px-2 my-4">
                        <Formik
                          initialValues={{
                            amount: "",
                            userName: "",
                            redToken: "",
                            walletAddress: "",
                            accountType: "Primary",
                          }}
                          validationSchema={SignupSchema}
                          onSubmit={(values, actions) => {
                            setTimeout(() => {
                              onFormSubmit(values, actions);
                              actions.setSubmitting(false);
                            }, 500);
                          }}
                        >
                          {({
                            handleSubmit,
                            errors,
                            touched,
                            values,
                            setFieldValue,
                          }) => (
                            <Form
                              onSubmit={handleSubmit}
                              className="row user-form"
                            >
                              <Form.Group
                                className="col-12 mb-3"
                                controlId="formBasicEmail"
                              >
                                <Form.Label>Enter User Name</Form.Label>
                                <Field
                                  onChange={(e) => {
                                    setFieldValue("userName", e.target.value);
                                    handleSearch(e.target.value);
                                  }}
                                  autoComplete="off"
                                  type="text"
                                  placeholder="Enter User Name"
                                  name="userName"
                                  className="form-ctl"
                                />
                                <h6 className="text-end mb-0 mt-1">
                                  {userFullName}
                                </h6>
                                {search1 == "" ? (
                                  ""
                                ) : (
                                  <div className="customSearch">
                                    {userData.length == 0 ? (
                                      <div> No Data Found. </div>
                                    ) : (
                                      userData?.map((item, i) => (
                                        <div
                                          onClick={() => {
                                            setFieldValue(
                                              "walletAddress",
                                              item?.walletAddress
                                            );
                                            setFieldValue(
                                              "userName",
                                              item?.userName
                                            );
                                            SetUserFullName(
                                              item?.name + " " + item?.lastname
                                            );
                                            SetSearch("");
                                          }}
                                          className="items"
                                          key={i}
                                        >
                                          <div
                                            style={{
                                              padding: "5px 0px",
                                              borderBottom:
                                                "1px solid #2c29294d",
                                            }}
                                          >
                                            {item?.userName}
                                            {" - "}
                                            {item?.name + " " + item?.lastname}
                                          </div>
                                        </div>
                                      ))
                                    )}
                                  </div>
                                )}
                                {errors.userName && touched.userName ? (
                                  <div className="text-danger">
                                    {errors.userName}
                                  </div>
                                ) : null}
                              </Form.Group>
                              <Form.Group className="col-12 mb-4" controlId="">
                                <Form.Label>Enter Wallet Address</Form.Label>
                                <Field
                                  type="text"
                                  placeholder="Enter Wallet Address"
                                  name="walletAddress"
                                  className="form-ctl"
                                />
                                {errors.walletAddress &&
                                  touched.walletAddress ? (
                                  <div className="text-danger">
                                    {errors.walletAddress}
                                  </div>
                                ) : null}
                              </Form.Group>

                              <Form.Group className="col-12 mb-4" controlId="">
                                <Form.Label>Enter RED Amount</Form.Label>
                                <Field
                                  type="number"
                                  placeholder="Enter amount"
                                  name="redToken"
                                  className="form-ctl"
                                  value={values.redToken}
                                  onChange={(e) => {
                                    setFieldValue("redToken", e.target.value);
                                    setFieldValue("amount", e.target.value * conversionRate);
                                  }}
                                />
                                {errors.redToken && touched.redToken ? (
                                  <div className="text-danger">
                                    {errors.redToken}
                                  </div>
                                ) : null}
                              </Form.Group>
                              <Form.Group className="col-12 mb-3" controlId="formBasicEmail">
                                <Form.Label>Enter Amount USD</Form.Label>
                                <Form.Control
                                  type="number"
                                  placeholder="Enter amount"
                                  name="amount"
                                  className="form-ctl"
                                  value={values.amount}
                                  onChange={(e) => {
                                    setFieldValue("amount", e.target.value);
                                    setFieldValue("redToken", e.target.value / conversionRate);
                                  }}
                                  disabled={conversionRate == 0}
                                />
                              </Form.Group>


                              <div className="col-12">
                                {loader ? (
                                  <Button
                                    // disabled={loader}
                                    className="w-100 common-btn"
                                    variant="primary"
                                  >
                                    <span className="typeWriter">
                                      processing<span> . . . . .</span>
                                    </span>
                                  </Button>
                                ) : (
                                  <Button
                                    // disabled={loader}
                                    className="w-100 common-btn"
                                    variant="primary"
                                    type="submit"
                                  >
                                    Send
                                  </Button>
                                )}
                              </div>
                            </Form>
                          )}
                        </Formik>
                      </div>
                    </Tab>
                    <Tab eventKey="outsideplatform" title="Outside Solos">
                      <div className="px-2 my-4">
                        <Formik
                          initialValues={{
                            amount: "",
                            userName: "",
                            walletAddress: "",
                            redToken: "",
                            accountType: "Primary",
                          }}
                          validationSchema={SignupSchema.omit('userName')}
                          onSubmit={(values, actions) => {
                            setTimeout(() => {
                              onFormSubmit(values, actions);
                              actions.setSubmitting(false);
                            }, 500);
                          }}
                        >
                          {({
                            handleSubmit,
                            errors,
                            values,
                            touched,
                            setFieldValue,
                          }) => (
                            <Form
                              onSubmit={handleSubmit}
                              className="row user-form"
                            >
                              <Form.Group className="col-12 mb-4" controlId="">
                                <Form.Label>Enter Wallet Address</Form.Label>
                                <Field
                                  type="text"
                                  placeholder="Enter Wallet Address"
                                  name="walletAddress"
                                  className="form-ctl"
                                />
                                {errors.walletAddress &&
                                  touched.walletAddress ? (
                                  <div className="text-danger">
                                    {errors.walletAddress}
                                  </div>
                                ) : null}
                              </Form.Group>

                              <Form.Group className="col-12 mb-4" controlId="">
                                <Form.Label>Enter RED Amount</Form.Label>
                                <Field
                                  type="number"
                                  placeholder="Enter amount"
                                  name="redToken"
                                  className="form-ctl"
                                  value={values.redToken}
                                  onChange={(e) => {
                                    setFieldValue("redToken", e.target.value);
                                    setFieldValue("amount", e.target.value * conversionRate);
                                  }}
                                />
                                {errors.redToken && touched.redToken ? (
                                  <div className="text-danger">
                                    {errors.redToken}
                                  </div>
                                ) : null}
                              </Form.Group>
                              <Form.Group className="col-12 mb-3" controlId="formBasicEmail">
                                <Form.Label>Enter Amount USD</Form.Label>
                                <Form.Control
                                  type="number"
                                  placeholder="Enter amount"
                                  name="amount"
                                  className="form-ctl"
                                  value={values.amount}
                                  onChange={(e) => {
                                    setFieldValue("amount", e.target.value);
                                    setFieldValue("redToken", e.target.value / conversionRate);
                                  }}
                                  disabled={conversionRate == 0}
                                />
                              </Form.Group>

                              <div className="col-12">
                                {loader ? (
                                  <Button
                                    // disabled={loader}
                                    className="w-100 common-btn"
                                    variant="primary"
                                  >
                                    <span className="typeWriter">
                                      processing<span> . . . . .</span>
                                    </span>
                                  </Button>
                                ) : (
                                  <Button
                                    // disabled={loader}
                                    className="w-100 common-btn"
                                    variant="primary"
                                    type="submit"
                                  >
                                    Send
                                  </Button>
                                )}
                              </div>
                            </Form>
                          )}
                        </Formik>
                      </div>
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <PinConfirm
        setShow={setShow}
        formData={formData}
        showPinModal={showPinModal}
        handleSubmit={sendCustomAssets}
        handleClosePinModal={handleClosePinModal}
      />
    </>
  );
};

export default Receive;
