import { Navigate } from "react-router-dom";
import SignIn from "../auth/SignIn";
import Layout from "../components/Layout/Layout";
import Accounts from "../pages/Accounts";
import Dashboard from "../pages/Dashboard";
import { Protectedpaths, Publicpaths } from "./paths";
import Possessions from "../pages/Possessions";
import Tvt from "../pages/Tvt";
import Marketplace from "../pages/Marketplace";
import ViewAllNfts from "../pages/ViewAllNfts";
import ViewAllSellNfts from "../pages/ViewAllSellNfts";
import ViewAllSellTvts from "../pages/ViewAllSellTvts";
import ViewAllSellSfreds from "../pages/ViewAllSellSfreds";
import ViewAllSellFreds from "../pages/ViewAllSellFreds";
import ViewAllFreds from "../pages/ViewAllFreds";
import ViewAllTvts from "../pages/ViewAllTvts";
import ViewAllSfreds from "../pages/ViewAllSfreds";
import ViewAllBuyFred from "../pages/ViewAllBuyFred";
import ViewAllBuyNfts from "../pages/ViewAllBuyNfts";
import ViewAllBuySfreds from "../pages/ViewAllBuySfreds";
import ViewAllBuyTvts from "../pages/ViewAllBuyTvts";
import NftDetail from "../components/Nft/NftDetail";
import SellNftDetail from "../components/Nft/SellNftDetail";
import SellTvtDetail from "../components/Tvt/SellTvtDetail";
import SellFredDetail from "../components/Fred/SellFredDetail";
import SellSFredDetail from "../components/SuperFred/SellSFredDetail";
import FredDetail from "../components/Fred/FredDetail";
import TvtDetail from "../components/Tvt/TvtDetail";
import SuperFredDetail from "../components/SuperFred/SuperFredDetail";
import Support from "../pages/Support";
import Settings from "../pages/Settings";
import PossessionDetails from "../pages/PossessionDetails";
import PossessionsList from "../pages/PossessionsList";
import LeaseRequests from "../pages/LeaseRequest";
import Register from "../auth/Register";
import ForgotEmail from "../auth/ForgotEmail";
import Otp from "../auth/Otp";
import LandingPage from "../pages/LandingPage";
import ForgotOtp from "../auth/ForgotOtp";
import ForgotPassword from "../auth/ForgotPassword";
import Kyc from "../auth/Kyc/Kyc";
import Transactions from "../pages/Transactions";
import RecoverWallet from "../pages/RecoverWallet";
import SellSrDetail from "../components/SolosReward/SellSrDetail";
import OtpVerified from "../components/OtpVerified/OtpVerified";

let userInfo = localStorage.getItem("user-info") || "";
userInfo = userInfo ? JSON.parse(userInfo) : null;
const isKycDone = userInfo
  ? parseInt(userInfo?.data?.isKYCConfirmed) === 1
    ? 1
    : 0
  : 0;
console.log(userInfo, "userinfo");

const isKycVerified =
  localStorage.getItem("isKycCompleted") == "true" ? true : false;
// auth && isKycVerified
export const UseRoute = (auth) => [
  {
    // element: auth ? <Layout /> : <Navigate to={Publicpaths.getLogin()} />,
    element: auth ? <Layout /> : <Navigate to={Publicpaths.getLogin()} />,
    children: [
      { path: Protectedpaths.getDashboard(), element: <Dashboard /> },
      { path: Protectedpaths.getAccounts(), element: <Accounts /> },
      { path: Protectedpaths.getPossessions(), element: <Possessions /> },
      { path: Protectedpaths.getTVTs(), element: <Tvt /> },
      { path: Protectedpaths.getTransactions(), element: <Transactions /> },
      {
        path: Protectedpaths.getPossessionsList(),
        element: <PossessionsList />,
      },
      { path: Protectedpaths.getMarketplace(), element: <Marketplace /> },
      { path: Protectedpaths.getViewAllNfts(), element: <ViewAllNfts /> },
      {
        path: Protectedpaths.getViewAllSellNfts(),
        element: <ViewAllSellNfts />,
      },
      {
        path: Protectedpaths.getViewAllSellTvts(),
        element: <ViewAllSellTvts />,
      },
      {
        path: Protectedpaths.getViewAllSellSfreds(),
        element: <ViewAllSellSfreds />,
      },
      {
        path: Protectedpaths.getViewAllSellFreds(),
        element: <ViewAllSellFreds />,
      },
      { path: Protectedpaths.getViewAllFreds(), element: <ViewAllFreds /> },
      { path: Protectedpaths.getViewAllTvts(), element: <ViewAllTvts /> },
      { path: Protectedpaths.getViewAllSfreds(), element: <ViewAllSfreds /> },
      {
        path: Protectedpaths.getViewAllBuyFreds(),
        element: <ViewAllBuyFred />,
      },
      { path: Protectedpaths.getViewAllBuyNfts(), element: <ViewAllBuyNfts /> },
      {
        path: Protectedpaths.getViewAllBuySfreds(),
        element: <ViewAllBuySfreds />,
      },
      { path: Protectedpaths.getViewAllBuyTvts(), element: <ViewAllBuyTvts /> },
      { path: Protectedpaths.getNftDetails(), element: <NftDetail /> },
      { path: Protectedpaths.getSellNftDetails(), element: <SellNftDetail /> },
      { path: Protectedpaths.getSellTvtDetails(), element: <SellTvtDetail /> },
      {
        path: Protectedpaths.getSellFredDetails(),
        element: <SellFredDetail />,
      },
      {
        path: Protectedpaths.getSellSrDetails(),
        element: <SellSrDetail />,
      },
      {
        path: Protectedpaths.getSellSFredDetails(),
        element: <SellSFredDetail />,
      },
      { path: Protectedpaths.getTvtDetails(), element: <TvtDetail /> },
      {
        path: Protectedpaths.getSuperFredDetails(),
        element: <SuperFredDetail />,
      },
      { path: Protectedpaths.getFredDetails(), element: <FredDetail /> },
      // { path: Protectedpaths.getSupport(), element: <Support /> },
      { path: Protectedpaths.getSettings(), element: <Settings /> },
      { path: Protectedpaths.getRecoverWallet(), element: <RecoverWallet /> },
      { path: Protectedpaths.getLease(), element: <LeaseRequests /> },
      {
        path: Protectedpaths.getPossessionsDetail(),
        element: <PossessionDetails />,
      },
      { path: "*", element: <Dashboard /> },
    ],
  },
  {
    path: Publicpaths.getRoot(),
    element: <LandingPage />,
  },
  {
    path: Publicpaths.getLogin(),
    element: auth ? (
      <Navigate to={Protectedpaths.getDashboard()} />
    ) : (
      <SignIn />
    ),
    //     auth == null ? (
    //   <SignIn />
    // ) : (
    //   <Navigate to={Protectedpaths.getDashboard()} />
    // ),
    // : localStorage.getItem("isKycCompleted") == true ? (
    //   <Navigate to={Protectedpaths.getDashboard()} />
    // ) : (
    //   <Navigate to={Publicpaths.getKyc()} />
    // ),
  },
  {
    path: Publicpaths.getRegister(),
    element:
      auth == null ? (
        <Register />
      ) : (
        <Navigate to={Protectedpaths.getDashboard()} />
      ),
  },
  {
    path: Publicpaths.getForgotEmail(),
    element:
      auth == null ? (
        <ForgotEmail />
      ) : (
        <Navigate to={Protectedpaths.getDashboard()} />
      ),
  },
  {
    path: Publicpaths.getOtp(),
    element:
      auth == null ? <Otp /> : <Navigate to={Protectedpaths.getDashboard()} />,
  },
  {
    path: Publicpaths.getOtpVerified(),
    element:
      auth == null ? (
        <OtpVerified />
      ) : (
        <Navigate to={Protectedpaths.getDashboard()} />
      ),
  },
  {
    path: Publicpaths.getForgotOtp(),
    element:
      auth == null ? (
        <ForgotOtp />
      ) : (
        <Navigate to={Protectedpaths.getDashboard()} />
      ),
  },
  {
    path: Publicpaths.getForgotPassword(),
    element:
      auth == null ? (
        <ForgotPassword />
      ) : (
        <Navigate to={Protectedpaths.getDashboard()} />
      ),
  },

  // {
  //   path: Publicpaths.getKyc(),
  //   element:
  //     auth == null ? (
  //       <Navigate to={Publicpaths.getLogin()} />
  //     ) : localStorage.getItem("isKycCompleted") == "true" ? (
  //       <Navigate to={Protectedpaths.getDashboard()} />
  //     ) : (
  //       <Kyc />
  //     ),
  // },
];
