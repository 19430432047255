import React, { useEffect, useState } from "react";
import "./Transactions.css";
import { BiChevronRight } from "react-icons/bi";
import { Link } from "react-router-dom";
import Buy from "../../images/icon7.svg";
import Receive from "../../images/icon8.svg";
import Send from "../../images/icon9.svg";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Usdt from "../../images/usdt.svg";
import moment from "moment";
import momentTimeZone from "moment-timezone";
import Pagination from "@mui/material/Pagination";
import { BsArrowUpRightCircle } from "react-icons/bs";
import { BsArrowDownLeftCircle } from "react-icons/bs";
import {
  getTokenTrasactions,
  getSrTrasactions,
} from "../../services/propertyServices";
import ViewTrasactionDetails from "../Modals/viewTrasactionDetails";
import Modal from "react-bootstrap/Modal";
import { FaCircleChevronLeft, FaCopy } from "react-icons/fa6";
import Loader from "../loader/loader";
import { fireToast } from "../../common/Toster";
import NoData from "../NoDataComp/NoData";

const Transactions = (data) => {
  const [modalData, setModalData] = useState("");
  const [activeTab, setActiveTab] = useState("RED");
  const [marketType, setMaretType] = useState("SOLOS");
  const [viewDetail, setViewDetail] = useState(false);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [apiData, setApiData] = useState([]);
  const pageSize = data?.pageSizeProp ? data?.pageSizeProp : 8;

  const navData = [
    {
      name: "RED",
    },
    {
      name: "SR",
    },
    {
      name: "USDC",
    },
    {
      name: "TVT",
    },
    {
      name: "FRED",
    },
    {
      name: "SFRED",
    },
  ];

  const getTransactionData = async () => {
    try {
      setLoading(true);
      const response = await getTokenTrasactions(
        page,
        pageSize,
        activeTab,
        marketType
      );
      if (response.status === 200) {
        setApiData(response?.data);
        setLoading(false);
      } else {
        throw new Error(response.error);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching nft data:", error);
    }
  };
  useEffect(() => {
    getTransactionData();
  }, [page, activeTab, marketType]);
  const handleModal = (item) => {
    setViewDetail(true);
    setModalData(item);
  };
  const handleClose = () => setViewDetail(false);
  const handleShow = () => setViewDetail(true);
  const handleCopy = (value) => {
    navigator.clipboard.writeText(value);
    fireToast("success", "Copied!");
  };
  return (
    <>
      <div className="d-flex align-items-center justify-content-between">
        <h6 className="fw-500 text-black mb-0" style={{ fontSize: "20px" }}>
          {window.location.pathname != "/transactions" && "Recent Transactions"}
        </h6>
      </div>

      {window.location.pathname == "/transactions" && (
        <div class="alert alert-warning p-2" role="alert">
          <p style={{ fontSize: "12px" }}>
            <stong class="fw-bold">Please note :</stong> Blockchain transactions
            may take 15 minutes or more. We appreciate your patience.
          </p>
        </div>
      )}

      <>
        <nav>
          <div
            class="nav nav-tabs transaction-navs my-4"
            id="nav-tab"
            role="tablist"
          >
            {/* <button
              onClick={() => {
                setActiveTab("");
                setPage(1);
              }}
              class={`nav-link ${activeTab == "" ? "active" : ""}`}
              id="nav-home-tab"
              data-bs-toggle="tab"
              data-bs-target="#nav-home"
              type="button"
              role="tab"
              aria-controls="nav-home"
              aria-selected="true"
            >
              All
            </button> */}
            {navData?.map((item, i) => {
              return (
                <button
                  onClick={() => {
                    setActiveTab(item?.name);
                    setMaretType("SOLOS")
                    setPage(1);
                  }}
                  class={`nav-link ${activeTab == item?.name ? "active" : ""}`}
                  id="nav-profile-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-profile"
                  style={{ border: "none" }}
                  type="button"
                  role="tab"
                  aria-controls="nav-profile"
                  aria-selected="false"
                >
                  {item?.name}
                </button>
              );
            })}
          </div>
        </nav>
      </>

      {!["RED", "USDC"].includes(activeTab) && (
        <div className="">
          <div className="dashboard-centerd">
            <div className="row g-2" style={{ width: "100%" }}>
              <div className="col-xl-12 col-xxl-12">
                <div className="">
                  {
                    activeTab === "SR" ?
                      <Tabs
                        defaultActiveKey={"SOLOS"}
                        onSelect={(k) => setMaretType(k)}
                        id="sr_tabs"
                        className="marketplace-tabs border-0 mb-4"
                      >
                        <Tab eventKey="SOLOS" title="Private"></Tab>
                        <Tab eventKey="REWARD" title="Rewards"></Tab>
                      </Tabs>
                      :
                      <Tabs
                        defaultActiveKey={"SOLOS"}
                        onSelect={(k) => setMaretType(k)}
                        id="marketplace_tabs"
                        className="marketplace-tabs border-0 mb-4"
                      >
                        <Tab eventKey="USER" title="User Market"></Tab>
                        <Tab eventKey="SEND_RECEIVE" title="Private"></Tab>
                        <Tab eventKey="SOLOS" title="Solos Market"></Tab>
                      </Tabs>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="table-responsive transaction-data">
        <table className="w-100">
          {loading ? (
            <Loader />
          ) : (
            <tbody>
              {apiData?.items?.length === 0 ? (
                <div style={{ margin: "20px" }}>
                  {" "}
                  <NoData />
                </div>
              ) : (
                apiData?.items?.map((item, i) => (
                  <tr
                    style={{ cursor: "pointer" }}
                    onClick={() => handleModal(item)}
                  >
                    <td>
                      <div className="trans-list d-flex align-items-center">
                        <i>
                          {item?.transactionType?.includes("Send") ||
                            item?.transactionType2?.includes("Send") ||
                            item?.transactionType2?.includes("Sell") ||
                            item?.transactionType?.includes("Sell") ? (
                            // <img src={Send} alt="" />
                            <BsArrowUpRightCircle color="red" size={30} />
                          ) : item?.transactionType?.includes("Buy") ||
                            item?.transactionType2?.includes("Buy") ||
                            item?.transactionType?.includes("Purchased") ||
                            item?.transactionType2?.includes("Purchased") ||
                            item?.transactionType2?.includes("Receive") ||
                            item?.transactionType?.includes("Receive") ? (
                            // <img src={Receive} alt="" />
                            <BsArrowDownLeftCircle color="green" size={30} />
                          ) : (
                            <BsArrowDownLeftCircle color="green" size={30} />
                          )}
                        </i>
                        <div className="ps-2">
                          <h6 className="mb-1 fw-600">
                            {
                              marketType === "REWARD" ?
                              (` ${item?.tokenData?.symbol} - Reward`)
                              : (
                                  item?.transactionType2 == null
                                    ? item?.transactionType +
                                    " " +
                                    ` ${item?.tokenData?.categories
                                      ? " - " + item?.tokenData?.categories
                                      : item?.tokenData?.name
                                        ? " - " + item?.tokenData?.name
                                        : ""
                                    }`
                                    : item?.transactionType2
                                )

                            }
                            { }
                          </h6>
                          <p
                            className={`f-12 status ${item?.status == "APPROVED" || item?.status == "COMPLETED"
                              ? "completed"
                              : item?.status == "PENDING"
                                ? "pending"
                                : "declined"
                              } `}
                          >
                            {item?.status}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td className="text-end">
                      <h6 className="fw-600 mb-2">
                        {(+item?.quantity)?.toFixed(4)}{" "}
                        {["RED", "SR", "USDC"].includes(activeTab)
                          ? activeTab
                          : item?.tokenData?.symbol}
                      </h6>
                      <p className="f-12">
                        {marketType === "REWARD" ? "Rewarded on" :"Order placed"} on{" "}
                        {momentTimeZone
                          .utc(item?.updatedAt)
                          .tz("America/Los_Angeles")
                          .format("lll")}
                      </p>
                    </td>
                  </tr>
                ))
              )}

              <tr>
                <div
                  style={{
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  {window.location.pathname == "/transactions" &&
                    apiData?.totalItems > 8 ? (
                    <Pagination
                      color="primary"
                      count={Math.ceil(apiData?.totalItems / 8)}
                      page={page}
                      siblingCount={0}
                      onChange={(e, v) => setPage(v)}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </tr>
            </tbody>
          )}
        </table>
      </div>
      {viewDetail ? (
        <Modal
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={viewDetail}
          onHide={handleClose}
          animation={false}
        >
          <Modal.Body>
            <span
              className="back-btn fw-500 text-black"
              onClick={() => handleClose()}
            >
              <i className="pe-2 align-middle">
                <FaCircleChevronLeft />
              </i>
              Transactions History
            </span>
            <div className="px-3 my-4">
              <table className="w-100">
                <tbody>
                  <tr>
                    <td>
                      <h6 className="fw-600 mb-2">Transaction Type</h6>
                    </td>
                    <td className="text-end">
                      <div className="trans-list d-flex align-items-center">
                        {modalData?.transactionType}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6 className="fw-600 mb-2">Quantity</h6>
                    </td>
                    <td className="text-end">
                      <div className="trans-list d-flex align-items-center">
                        {(+modalData?.quantity)?.toFixed(4)}{" "}
                        {["RED", "SR", "USDC"].includes(activeTab)
                          ? activeTab
                          : modalData?.tokenData?.symbol}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6 className="fw-600 mb-2">Sent By</h6>
                    </td>
                    <td className="text-end">
                      <div className="trans-list d-flex align-items-center">
                        {modalData?.senderUsername}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6 className="fw-600 mb-2">Sent To</h6>
                    </td>
                    <td className="text-end">
                      <div className="trans-list d-flex align-items-center">
                        {modalData?.receiverUsername}
                      </div>
                    </td>
                  </tr>
                  {modalData?.hashId && (
                    <tr>
                      <td>
                        <h6 className="fw-600 mb-2">Transaction Id</h6>
                      </td>
                      <td className="text-end">
                        <div className="trans-list d-flex align-items-center">
                          {modalData?.hashId?.slice(0, 10)}...&nbsp;
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => handleCopy(modalData?.hashId)}
                          >
                            <FaCopy />
                          </span>
                        </div>
                      </td>
                    </tr>
                  )}

                  <tr>
                    <td>
                      <h6 className="fw-600 mb-2">Date</h6>
                    </td>
                    <td className="text-end">
                      <div className="trans-list d-flex align-items-center">
                        {momentTimeZone
                          .utc(modalData?.updatedAt)
                          .tz("America/Los_Angeles")
                          .format("lll")}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Modal.Body>
        </Modal>
      ) : (
        ""
      )}
    </>
  );
};

export default Transactions;
