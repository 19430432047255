import { useState, useEffect } from "react";
import "./Modal.css";
import { IoCloseSharp } from "react-icons/io5";
import { FaCircleChevronLeft, FaCopy } from "react-icons/fa6";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Recive from "../../images/recive.svg";
import qr from "../../images/qr.svg";
import copy from "../../images/copy.svg";
import share from "../../images/share.svg";
import { Link } from "react-router-dom";
import { fireToast, kycErrorPopUp } from "../../common/Toster";
import { getMyInfo } from "../../services/propertyServices";

const Receive = ({ title }) => {
  const isKycVerified =
    localStorage.getItem("isKycCompleted") == "true" ? true : false;

  const [show, setShow] = useState(false);
  const [username, setUsername] = useState("");
  const [address, setAddress] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const getdata = async () => {
    await getMyInfo()
      .then((res) => {
        setUsername(res?.data?.userName);
        setAddress(res?.data?.walletAddress);
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
  useEffect(() => {
    getdata();
  }, []);

  return (
    <>
      <Button
        className="bg-transparent border-0 p-0"
        variant="primary"
        onClick={() => {
          // if (isKycVerified) {
            handleShow();
          // } else {
          //   kycErrorPopUp();
          // }
        }}
      >
        <span className="token-btn d-flex align-items-center justify-content-center mx-auto mb-1">
          <img src={Recive} alt="" />
        </span>
        <p className="scard-btext">Receive</p>
      </Button>

      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
        animation={false}
      >
        <Modal.Body>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <span
                style={{ cursor: "pointer" }}
                className="back-btn fw-500 text-black "
              >
                Receive {title}
              </span>
            </div>
            <div>
              <span
                onClick={() => handleClose()}
                style={{ cursor: "pointer", fontSize: "22px" }}
              >
                <IoCloseSharp />
              </span>
            </div>
          </div>

          <div className="px-5 text-center my-4">
            <p className="mt-2 mb-4">
              User-Name : <span className="fw-600 text-black">{username} </span>
            </p>
            <p className="mt-2 mb-4">
              Wallet Address :{" "}
              <span className="fw-600 text-black">
                {address?.toString()?.slice(0, 6)}...
                {address?.toString()?.slice(36, 42)}{" "}
              </span>
              <i
                className="text-black"
                onClick={() => {
                  navigator.clipboard.writeText(address);
                  fireToast("success", "Copied!");
                }}
              >
                <FaCopy />
              </i>
            </p>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Receive;
