import React, { useEffect, useState, useContext } from "react";
// import { Link, useSearchParams } from "react-router-dom";
import { FaCircleChevronLeft, FaCopy } from "react-icons/fa6";
import { IoCloseSharp } from "react-icons/io5";
import "./BooqBalance.css";
// import Solos from "../../images/newcardlogo.svg";
import configURl from "../../runtime.config";
import PinConfirm from "../Modals/PinConfirm";
import Recive from "../../images/recive.svg";
import { ConversionContext } from "../../Context/context";
import Send from "../../images/send.svg";
import {
  getSoloAssets,
  //  walletData
} from "../../services/propertyServices";
import Solos from "../../images/newcardlogo.svg";
import loaderGif from "../../images/loader.gif";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Buyicon from "../../images/buy.svg";
import bAdd from "../../images/b_create.svg";
// import usdcAbi from "../../Contracts/usdc.json";
import bKYC from "../../images/b_kyc_pending.svg";
import bAccAdd from "../../images/b_bank_add.svg";
import bAccList from "../../images/b_transaction_list.svg";
// import { getContractInstance } from "../../web3/web3";
import bTrans from "../../images/b_transaction.svg";
// import Booq from "./BooqConfig";
// import axios from "axios";
// import { BigNumber, Signer } from "ethers";
import threedots from "../../images/more2.png";
import Modal from "react-bootstrap/Modal";
import { toastConfirm } from "../../common/Toster";
import Table from "react-bootstrap/Table";

import {
  addBankAccountToBridge,
  generateBridgeKycLink,
  generateBrigeLink,
  getListOfExternalAcc,
  getListOfTransactions,
  transferFiatToCoin,
  withdarwAmountFromBridge,
} from "../../services/bridgeServices";
import {
  fireToast,
  kycErrorPopUp,
  booqErrorPopupForStates,
} from "../../common/Toster";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import * as formik from "formik";
import * as yup from "yup";
import { dateFormat } from "../../common/utility";
import {
  getAllClientUsers,
  getMyInfo,
  transaction,
} from "../../services/propertyServices";
import { useAddress, useContract, useSigner } from "@thirdweb-dev/react";
import { BigNumber, ethers } from "ethers";
import usdcAbi from "../../Contracts/usdc.json";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const BooqBalance = ({ getTokenBalance, userInfo, UsdcTokenData }) => {
  const contextData = useContext(ConversionContext);
  const [formData, setFormData] = useState({});
  const [showPinModal, setShowPinModal] = useState(false);
  const { Formik, Field } = formik;
  const address = useAddress();
  const [userFullName, SetUserFullName] = useState("");
  const signer = useSigner();
  const [loading, setLoading] = useState(false);
  const [show, setShow] = React.useState(false);
  const [showTranBuy, setShowTransBuy] = React.useState(false);
  const [showBankAcc, setShowBankAcc] = React.useState(false);
  const [showBankList, setShowBankList] = React.useState(false);
  const [showBankWith, setShowBankWith] = React.useState(false);
  const [items, setItems] = useState([]);
  const [showReceive, setShowReceive] = React.useState(false);
  const [showSend, setShowSend] = React.useState(false);
  const [paymentMethodDeposit, setPaymentMethodDeposit] = useState("wire");
  const [paymentMethodWithdraw, setPaymentMethodWithdraw] = useState("wire");
  const [loader, setLoader] = useState(false);
  const [search1, SetSearch] = useState("");
  const [userData, setUserData] = useState([]);
  const [amount, setAmount] = useState(0);
  const handleClosePinModal = () => {
    setShowPinModal(false);
  };
  // console.log(amount, "amountttttt");
  const isPinActive = contextData?.userData?.isMpinActive;
  const {
    contract: usdcContract,
    isLoading: loadingUsdc,
    error: usdcError,
  } = useContract(configURl.usdcAddress);

  const fetchUserData = async (
    pageNumber = 1,
    pageSize = 5,
    search = search1
  ) => {
    try {
      const list = await getAllClientUsers(pageNumber, pageSize, search);
      if (list.status === 200) {
        setUserData(list?.data?.items);
      } else {
        throw new Error(list.error);
      }
    } catch (err) { }
  };

  useEffect(() => {
    fetchUserData();
  }, [search1]);

  const handleSearch = (value) => {
    SetSearch(value);
    // console.log(value, "test values");
    fetchUserData();
  };

  const SignupSchema = yup.object().shape({
    // userName: yup.string().required("User name is required"),
    // redToken: yup.string().required("Amount is required"),
    walletAddress: yup
      .string()
      .min("42")
      .max("42")
      .required("Address is required"),
  });

  const onFormSubmit = async (data, actions) => {
    toastConfirm(
      "Are you sure?",
      `You want to send ${amount} USDC tokens to ${data?.userName ? data?.userName : data?.walletAddress
      }?`
    )
      .fire()
      .then(async (val) => {
        if (val.isConfirmed) {
          (isPinActive
            ? () => {
              setShowSend(false);
              setFormData(data);
              setShowPinModal(true);
            }
            : () => {
              sendCustomAssets(data);
            })();
          // sendCustomAssets(data);
        }
      });
  };
  // wallet
  const sendCustomAssets = async (data) => {
    // setLoader(true);
    try {
      // const tx = await usdcContract.erc20.transfer(
      //   data?.walletAddress,
      //   data?.redToken
      // );
      // const response = tx.receipt;
      // console.log(response);
      setLoader(true);
      // const amountToBePassed = BigNumber.from(amount).mul(
      //   BigNumber.from(10).pow(6)
      // );
      if (amount <= 0) {
        fireToast("error", "Amount must be greater than 0 !");
        return;
      }
      const amt = Number(amount).toFixed(5).toString();
      const amountToBePassed = ethers.utils.parseUnits(amt, 6);

      const maticBalance = ethers.utils.formatEther(await signer.getBalance());
      // const estimatedGasPrice = ethers.utils.formatEther(
      //   await signer.getGasPrice()
      // );
      // console.log(maticBalance, "maticBalance");
      // console.log(estimatedGasPrice, "estimatedGasPrice");
      if (+maticBalance < 1) {
        fireToast("error", "Insufficient matic funds for gas price !");
        setLoader(false);
        return;
      }

      const instance = new ethers.Contract(
        configURl.usdcAddress,
        usdcAbi,
        signer
      );
      const balance = ethers.utils.formatUnits(
        await instance.balanceOf(address),
        6
      );
      // console.log(balance, "formattedBalance");
      if (+balance < +amt) {
        fireToast("error", "Transfer amount exceeds balance !");
        setLoader(false);
        return;
      }

      const tx = await instance.transfer(
        data?.walletAddress,
        amountToBePassed,
        {
          gasLimit: 600000,
        }
      );
      // console.log(tx, "transaction");
      const response = await tx;
      // console.log(response, "transaction receipt");
      if (response) {
        getTokenBalance();
        setLoader(false);
        transaction({
          walletAddress: data.walletAddress,
          transactionType: "Usdc Token",
          token: amount,
          userName: data?.userName,
          hashId: response.hash,
        }).then((res) => {
          // console.log(res, "res");
          setShowSend(false);
        });
        fireToast("success", "Successfully Sent.");
        setShowSend(false);
      }
    } catch (err) {
      // fireToast("error", err);
      console.log(err, "sendCustomAssets error");
    } finally {
      setLoader(false);
      setShowSend(false);
    }
  };

  const handleBridgeCreate = async () => {
    try {
      let userInfo = await getMyInfo();
      userInfo = userInfo.data;
      // if (userInfo?.isKYCConfirmed == 0 || userInfo?.isKYCConfirmed == "0") {
      //   kycErrorPopUp();
      // } else 
      if (
        userInfo?.state == "LA" ||
        userInfo?.state == "NY" ||
        userInfo?.state == "FL" ||
        userInfo?.state == "AK"
      ) {
        booqErrorPopupForStates();
      } else {
        if (
          !userInfo?.street_line_1 ||
          !userInfo?.city ||
          !userInfo?.state ||
          !userInfo?.postal_code ||
          !userInfo?.countryShortCode ||
          !userInfo?.tax_identification_number ||
          !userInfo?.birth_date
        ) {
          throw new Error("Please first complete your profile!");
        }

        setLoading(true);
        let result = await generateBrigeLink();
        if (result.status == 200) {
          let modeType = window.location.hostname.includes("localhost")
            ? "Local"
            : window.location.hostname.includes("dev")
              ? "Dev"
              : "Live";

          let redirectUri = "https://users.solosfi.com/dashboard";
          if (modeType == "Local") {
            redirectUri = "http://localhost:3000/dashboard";
          } else if (modeType == "Dev") {
            redirectUri = "https://dev-admin.solosfi.com/dashboard";
          }

          window.location.href =
            result.data.url + "&redirect_uri=" + redirectUri;
        }
      }
    } catch (error) {
      fireToast(
        "error",
        error?.response?.data?.error
          ? error?.response?.data?.error
          : error.message
            ? error.message
            : "Something went wrong!"
      );
    } finally {
      setLoading(false);
    }
  };

  const [bridgeUser, setBridgeUser] = useState(null);
  const handleBridgeKYC = async (email, type) => {
    try {
      setLoading(true);
      // let result = await generateBridgeKycLink("divya.kumari@yopmail.com");
      let result = await generateBridgeKycLink(email);

      if (result.status == 200) {
        if (result?.data) {
          if (type == "byclick" && result?.data?.kyc_status != "approved") {
            window.open(result?.data?.kyc_link, "", "width=500, height=650");
          } else if (result?.data?.kyc_status == "approved") {
            setBridgeUser(result?.data ? result?.data : null);
          }
        }
      }
    } catch (error) {
      fireToast(
        "error",
        error?.response?.data?.error
          ? error?.response?.data?.error
          : error.message
            ? error.message
            : "Something went wrong!"
      );
    } finally {
      setLoading(false);
    }
  };

  const [listTransaction, setListTransactions] = useState([]);
  const handleListOfBuyRequest = async () => {
    try {
      setLoading(true);
      let result = await getListOfTransactions(userInfo.bridgeCustomerId);
      if (result.status == 200) {
        setListTransactions(result.data.data);
        setShowTransBuy(true);
      }
    } catch (error) {
      fireToast(
        "error",
        error?.response?.data?.error
          ? error?.response?.data?.error
          : error.message
            ? error.message
            : "Something went wrong!"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleBuyCoin = async () => {
    try {
      if (amount == 0) {
        throw new Error("Balance required greater than 0");
      }
      setLoading(true);
      setShow(false);
      let result = await transferFiatToCoin({
        customer_id: userInfo.bridgeCustomerId,
        wallet_address: address, //walletAddress,
        amount: amount,
        payment_type: paymentMethodDeposit,
      });

      if (result.data) {
        handleListOfBuyRequest();
      }
    } catch (error) {
      setShow(true);
      fireToast(
        "error",
        error?.response?.data?.error
          ? error?.response?.data?.error
          : error.message
            ? error.message
            : "Something went wrong!"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleCopy = (value) => {
    navigator.clipboard.writeText(value);
    fireToast("success", "Copied!");
  };

  const schema = yup.object().shape({
    bankName: yup.string().required("This field is required."),
    bankOwnerName: yup.string().required("This field is required."),
    accountNumber: yup.string().required("This field is required."),
    routingNumber: yup.string().required("This field is required."),
    streetLineOne: yup.string().required("This field is required."),
    streetLineTwo: yup.string().required("This field is required."),
    state: yup.string().required("This field is required."),
    city: yup.string().required("This field is required."),
    country: yup.string().required("This field is required."),
    postalCode: yup.string().required("This field is required."),
  });

  const handleAddBankAccountToBridge = async (event) => {
    try {
      setLoading(true);

      let result = await addBankAccountToBridge({
        customer_id: userInfo.bridgeCustomerId,
        routing_number: event?.routingNumber,
        account_number: event?.accountNumber,
        account_owner_name: event?.bankOwnerName,
        bank_name: event?.bankName,
        country: event?.country,
        postal_code: event?.postalCode,
        state: event?.state,
        city: event?.city,
        street_line_2: event?.streetLineTwo,
        street_line_1: event?.streetLineOne,
      });
      if (result.status == 200) {
        setShowBankAcc(false);
        showBankAccountListing();
        // console.log(result, "back account added");
      }
    } catch (error) {
      // setShowBankAcc(false)
      console.log(error, "error");
      fireToast(
        "error",
        error?.response?.data?.error
          ? error?.response?.data?.error
          : error.message
            ? error.message
            : "Something went wrong!"
      );
    } finally {
      setLoading(false);
    }
  };

  const [listAccounts, setListAccounts] = useState([]);
  const getBankListing = async () => {
    let result = await getListOfExternalAcc(userInfo.bridgeCustomerId);
    if (result.status == 200) {
      setListAccounts(result.data.data);
    }
    return result;
  };
  const showBankAccountListing = async () => {
    try {
      setLoading(true);
      let result = await getBankListing();
      if (result.status == 200) {
        setShowBankList(true);
      }
    } catch (error) {
      fireToast(
        "error",
        error?.response?.data?.error
          ? error?.response?.data?.error
          : error.message
            ? error.message
            : "Something went wrong!"
      );
    } finally {
      setLoading(false);
    }
  };

  const [withdrawAmount, setWithdrawAmount] = useState(0);
  const [externalAccountId, setExternalAccountId] = useState(null);
  const handleWithdrawUSDC = async () => {
    try {
      setLoading(true);
      let result = await getBankListing();
      setShowBankWith(true);
    } catch (error) {
      fireToast(
        "error",
        error?.response?.data?.error
          ? error?.response?.data?.error
          : error.message
            ? error.message
            : "Something went wrong!"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleWithdrawAountFromBridge = async () => {
    try {
      if (withdrawAmount == 0 || !externalAccountId) {
        throw new Error(
          "Balance required greater than 0 or Bank not selected!"
        );
      }

      setLoading(true);
      let result = await withdarwAmountFromBridge({
        customer_id: userInfo.bridgeCustomerId,
        wallet_address: address, // walletAddress,
        external_account_id: externalAccountId,
        amount: withdrawAmount,
        payment_type: paymentMethodWithdraw,
      });
      if (result.status == 200) {
        setShowBankWith(false);

        // Function ---- Amount Withdraw Function
        sendUsdc(result);

        handleListOfBuyRequest();
      }
    } catch (error) {
      fireToast(
        "error",
        error?.response?.data?.error
          ? error?.response?.data?.error
          : error.message
            ? error.message
            : "Something went wrong!"
      );
    } finally {
      setLoading(false);
    }
  };

  const sendUsdc = async (data) => {
    // console.log(
    //   data?.data?.source_deposit_instructions?.from_address,
    //   "sendUsdcData>>>>>>>>>>>>>>>>",
    //   address
    // );

    if (loadingUsdc) {
      throw new Error("Please try after few seconds contract is intalizing!");
    }
    if (usdcError) {
      // console.log(usdcError, "contract error");
      throw new Error("Error initializing contract!");
    }
    if (
      data?.data?.source_deposit_instructions?.from_address?.toLowerCase() ==
      address?.toLowerCase()
    ) {
      const tx = await usdcContract.erc20.transfer(
        `${data?.data?.source_deposit_instructions?.to_address}`,
        `${withdrawAmount}`
      );
      const response = tx?.receipt;
      // console.log(response, "response");
    } else {
      fireToast("error", "Wallet not matched");
    }
  };

  useEffect(() => {
    if (userInfo?.email) {
      handleBridgeKYC(userInfo?.email, "onload");
    }
    // handleConnectWallet();
  }, [userInfo]);

  const UsdcToken = UsdcTokenData;

  return (
    <>
      <div className="solos-card booq-card d-flex flex-column justify-content-between position-relative">
        <div className="token-head d-flex align-items-center justify-content-between">
          <div>
            <span className="text-white fw-400 scard-title"> USD</span>
            <h6 className="fs-24 text-white ps-1">
              $
              {UsdcToken
                ? Number(
                  Number(UsdcToken?.balance)
                    .toString()
                    .match(/^-?\d+(?:.\d{0,2})?/)[0]
                ).toLocaleString()
                : 0}
            </h6>
            {/* <h6 className="fs-24 text-white">0</h6> */}
            {/* <h6 className="fs-24 text-white text-start ps-1">
              {UsdcToken ? Number(UsdcToken?.balance)?.toFixed(2) : 0}
            </h6> */}
          </div>
          {/* <div className="text-end">
            <span className="text-white fw-600"> USD</span>
            <h6 className="fs-24 text-white text-start ps-1">
              $
              {UsdcToken
                ? Number(
                    Number(UsdcToken?.balance)
                      .toString()
                      .match(/^-?\d+(?:.\d{0,2})?/)[0]
                  ).toLocaleString()
                : 0}
            </h6>
          </div> */}
          <div>
            {userInfo?.bridgeCustomerId &&
              bridgeUser &&
              bridgeUser.kyc_status == "approved" ? (
              <div class="dropdown">
                <img
                  class="dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  src={threedots}
                  alt=""
                  style={{ width: "30px", cursor: "pointer" }}
                />
                <ul class="dropdown-menu">
                  <li className="d-flex justify-content-center">
                    {" "}
                    <a
                      href="javascript:void(0);"
                      className="me-0"
                      onClick={() => setShowBankAcc(true)}
                    >
                      <span className="token-btn d-flex align-items-center justify-content-center mx-auto mb-1">
                        <img src={bAccAdd} alt="" />
                      </span>
                      <span className="text-center fs-8 mb-0 font-card text-black fw-500">
                        Add Bank Account
                      </span>
                    </a>
                  </li>
                  <li className="d-flex justify-content-center">
                    {" "}
                    <a
                      href="javascript:void(0);"
                      className="me-0"
                      onClick={() => showBankAccountListing()}
                    >
                      <span className="token-btn d-flex align-items-center justify-content-center mx-auto mb-1">
                        <img src={bAccList} alt="" />
                      </span>
                      <span className="text-center fs-8 mb-0 font-card text-black fw-500">
                        Show Bank Accounts
                      </span>
                    </a>
                  </li>
                  <li className="d-flex justify-content-center">
                    <a
                      href="javascript:void(0);"
                      className="me-0"
                      onClick={handleListOfBuyRequest}
                    >
                      <span className="token-btn d-flex align-items-center justify-content-center mx-auto mb-1">
                        <img src={bTrans} alt="" />
                      </span>
                      <span className="text-center fs-8 mb-0 font-card text-black fw-500">
                        Show Transactions
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div>{/* <h4 className="text-white">Coming Soon</h4> */}</div>
        <div
          className=" token-bottom "
        // style={{
        //   position: "absolute",
        //   top: "50%",
        //   left: "50%",
        //   transform: "translate(-50%,-50%)",
        // }}
        >
          <div className="row d-flex align-items-center text-center">
            {loading ? (
              <>
                <a
                  href="javascript:void(0);"
                  onClick={handleBridgeCreate}
                  className="col-3 d-flex flex-column justify-content-center"
                >
                  <span className="token-btn d-flex align-items-center justify-content-center mx-auto mb-1">
                    <img src={loaderGif} alt="" />
                  </span>
                  <span className="text-center fs-8 mb-0 text-white fw-500">
                    Loading...
                  </span>
                </a>
              </>
            ) : userInfo?.bridgeCustomerId ? (
              <>
                {bridgeUser && bridgeUser.kyc_status == "approved" ? (
                  <>
                    <a
                      href="javascript:void(0);"
                      onClick={() => setShow(true)}
                      className="col-3 d-flex flex-column justify-content-center"
                    >
                      <span className="token-btn d-flex align-items-center justify-content-center mx-auto mb-1">
                        <img src={Buyicon} alt="" />
                      </span>
                      <div className="text-white scard-btext">Deposit</div>
                    </a>

                    <a
                      href="javascript:void(0);"
                      onClick={() => handleWithdrawUSDC()}
                      className="col-3 d-flex flex-column justify-content-center"
                    >
                      <span className="token-btn d-flex align-items-center justify-content-center mx-auto mb-1">
                        <img src={Recive} alt="" />
                      </span>
                      <div className="text-white scard-btext">Withdraw</div>
                    </a>
                  </>
                ) : (
                  <>
                    <a
                      href="javascript:void(0);"
                      className="col-3 d-flex flex-column justify-content-center"
                      onClick={() =>
                        handleBridgeKYC(userInfo?.email, "byclick")
                      }
                    >
                      <span className="token-btn d-flex align-items-center justify-content-center mx-auto mb-0 receive-tkn position-relative mb-1">
                        <img src={bKYC} alt="" />
                      </span>
                      <div className="text-white scard-btext">KYC</div>
                    </a>
                  </>
                )}
              </>
            ) : (
              <>
                <a
                  href="javascript:void(0);"
                  className="col-3 d-flex flex-column justify-content-center"
                  onClick={handleBridgeCreate}
                >
                  <span className="token-btn d-flex align-items-center justify-content-center mx-auto mb-1">
                    <img src={bAdd} alt="" />
                  </span>
                  <div className="text-white scard-btext">Start</div>
                </a>
              </>
            )}

            {!loading && (
              <>
                <a
                  href="javascript:void(0);"
                  className="col-3 d-flex flex-column justify-content-center"
                  onClick={() => setShowSend(true)}
                >
                  <span className="token-btn d-flex align-items-center justify-content-center mx-auto mb-0 receive-tkn position-relative mb-1">
                    <img src={Send} alt="" />
                  </span>
                  <div className="text-white scard-btext">Send</div>
                </a>

                <a
                  className="col-3 d-flex flex-column justify-content-center"
                  href="javascript:void(0);"
                  onClick={() => setShowReceive(true)}
                >
                  <span className="token-btn d-flex align-items-center justify-content-center mx-auto mb-0 receive-tkn position-relative mb-1">
                    <img src={Recive} alt="" />
                  </span>
                  <div className="text-white scard-btext">Receive</div>
                </a>
              </>
            )}
          </div>
        </div>
        <div
          className="text-center"
          style={{ position: "absolute", top: "20px", right: "20px" }}
        >
          <img src={Solos} alt="" height={24} />
        </div>
      </div>

      {/* Add Bank Account to Bridge  */}
      <Modal
        show={showBankAcc}
        onHide={() => setShowBankAcc(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <h6>Add Bank Account to Bridge</h6>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              validationSchema={schema}
              onSubmit={(e) => handleAddBankAccountToBridge(e)}
              initialValues={{
                bankName: "",
                bankOwnerName: "",
                accountNumber: "",
                routingNumber: "",
                streetLineOne: "",
                streetLineTwo: "",
                state: "",
                city: "",
                country: "",
                postalCode: "",
              }}
            >
              {({ handleSubmit, handleChange, values, touched, errors }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Row className="mb-3">
                    <Form.Group as={Col} md="6" controlId="validationFormik01">
                      <Form.Label>Bank Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Write here..."
                        name="bankName"
                        value={values.bankName}
                        onChange={handleChange}
                        isInvalid={!!errors.bankName}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.bankName}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="validationFormik02">
                      <Form.Label>Account Holder Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Write here..."
                        name="bankOwnerName"
                        value={values.bankOwnerName}
                        onChange={handleChange}
                        isInvalid={!!errors.bankOwnerName}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.bankOwnerName}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      md="6"
                      controlId="validationFormikUsername"
                    >
                      <Form.Label>Account Number</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Write here..."
                        name="accountNumber"
                        value={values.accountNumber}
                        onChange={handleChange}
                        isInvalid={!!errors.accountNumber}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.accountNumber}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="validationFormik03">
                      <Form.Label>Routing Number</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Write here..."
                        name="routingNumber"
                        value={values.routingNumber}
                        onChange={handleChange}
                        isInvalid={!!errors.routingNumber}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.routingNumber}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} md="6" controlId="validationFormik03">
                      <Form.Label>Street Line 1</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Write here..."
                        name="streetLineOne"
                        value={values.streetLineOne}
                        onChange={handleChange}
                        isInvalid={!!errors.streetLineOne}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.streetLineOne}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} md="6" controlId="validationFormik03">
                      <Form.Label>Street Line 2</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Write here..."
                        name="streetLineTwo"
                        value={values.streetLineTwo}
                        onChange={handleChange}
                        isInvalid={!!errors.streetLineTwo}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.streetLineTwo}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} md="6" controlId="validationFormik03">
                      <Form.Label>City</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Write here..."
                        name="city"
                        value={values.city}
                        onChange={handleChange}
                        isInvalid={!!errors.city}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.city}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} md="6" controlId="validationFormik03">
                      <Form.Label>
                        State{" "}
                        <span style={{ fontSize: "10px" }}>
                          (2 letter short code ex. CH)
                        </span>{" "}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Write here..."
                        name="state"
                        value={values.state}
                        onChange={handleChange}
                        isInvalid={!!errors.state}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.state}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} md="6" controlId="validationFormik03">
                      <Form.Label>Postal Code</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Write here..."
                        name="postalCode"
                        value={values.postalCode}
                        onChange={handleChange}
                        isInvalid={!!errors.postalCode}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.postalCode}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} md="6" controlId="validationFormik03">
                      <Form.Label>
                        Country{" "}
                        <span style={{ fontSize: "10px" }}>
                          (3 letter short code ex. IND)
                        </span>{" "}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Write here..."
                        name="country"
                        value={values.country}
                        onChange={handleChange}
                        isInvalid={!!errors.country}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.country}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                  <Button type="submit">Submit</Button>
                </Form>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>

      {/* Buy Fiat to Coin Modal  */}
      <Modal
        show={show}
        onHide={() => setShow(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <h6>Buy Fiat to USDC</h6>
        </Modal.Header>
        <Modal.Body>
          <div>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
              <Form.Control
                placeholder="Write here..."
                type="number"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                aria-describedby="basic-addon1"
              />
            </InputGroup>

            <label>Wallet Address</label>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon2">#</InputGroup.Text>
              <Form.Control
                placeholder="Write here..."
                type="text"
                value={address}
                aria-describedby="basic-addon2"
              />
            </InputGroup>

            <label>Select Payment Method</label>
            <Form.Select
              aria-label="Default select example mb-3"
              onChange={(e) => setPaymentMethodDeposit(e.target.value)}
            >
              <option value=""> Select </option>
              <option value="wire"> Wire </option>
              <option value="ach_push"> Ach Push </option>
            </Form.Select>
            <button
              className="btn btn-success mt-3"
              onClick={() => handleBuyCoin()}
            >
              Deposit
            </button>
          </div>
        </Modal.Body>
      </Modal>

      {/* Withdraw modal USDC to USD  */}
      <Modal
        show={showBankWith}
        onHide={() => setShowBankWith(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <h6>Withdraw USD to Fiat</h6>
        </Modal.Header>
        <Modal.Body>
          <div>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
              <Form.Control
                placeholder="Write here..."
                type="number"
                value={withdrawAmount}
                onChange={(e) => setWithdrawAmount(e.target.value)}
                aria-describedby="basic-addon1"
              />
            </InputGroup>

            <label>Wallet Address</label>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon2">#</InputGroup.Text>
              <Form.Control
                placeholder="Write here..."
                type="text"
                readOnly
                value={address}
                aria-describedby="basic-addon2"
              />
            </InputGroup>

            <label>Select Bank Account</label>
            <Form.Select
              aria-label="Default select example mb-3"
              onChange={(e) => setExternalAccountId(e.target.value)}
            >
              <option value=""> Select </option>
              {listAccounts &&
                listAccounts.length > 0 &&
                listAccounts.map((value, index) => {
                  return (
                    <option key={index} value={value?.id}>
                      {" "}
                      {value?.bank_name} - XXXXX{value?.last_4}{" "}
                    </option>
                  );
                })}
            </Form.Select>

            <label>Select Payment Method</label>
            <Form.Select
              aria-label="Default select example mb-3"
              onChange={(e) => setPaymentMethodWithdraw(e.target.value)}
            >
              <option value=""> Select </option>
              <option value="wire"> Wire </option>
              <option value="ach"> Ach Push </option>
            </Form.Select>
            <button
              className="btn btn-success mt-3"
              onClick={() => handleWithdrawAountFromBridge()}
            >
              Withdraw
            </button>
          </div>
        </Modal.Body>
      </Modal>

      {/* List of Fiat to Coin Modal  */}
      <Modal
        size="lg"
        show={showTranBuy}
        onHide={() => setShowTransBuy(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <h6>Transactions</h6>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Table striped bordered hover className="modal-font">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Type</th>
                  <th>Bank</th>
                  <th>Amount</th>
                  <th>Wallet</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {listTransaction && listTransaction.length > 0 ? (
                  listTransaction.map((value, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>
                          {value?.source_deposit_instructions?.bank_name ? (
                            <strong> Deposit </strong>
                          ) : (
                            <strong> Withdraw </strong>
                          )}
                        </td>
                        <td>
                          <ul>
                            {value?.source_deposit_instructions?.bank_name ? (
                              <>
                                <li>
                                  {" "}
                                  <strong>Bank Name: </strong>{" "}
                                  {
                                    value?.source_deposit_instructions
                                      ?.bank_name
                                  }{" "}
                                </li>
                                <li>
                                  {" "}
                                  <strong>Bank Address: </strong>{" "}
                                  {
                                    value?.source_deposit_instructions
                                      ?.bank_address
                                  }{" "}
                                </li>
                                <li>
                                  {" "}
                                  <strong>Bank Routing No.: </strong>{" "}
                                  {
                                    value?.source_deposit_instructions
                                      ?.bank_routing_number
                                  }{" "}
                                </li>
                                <li>
                                  {" "}
                                  <strong>Bank Account No.: </strong>{" "}
                                  {
                                    value?.source_deposit_instructions
                                      ?.bank_account_number
                                  }{" "}
                                </li>
                                <li>
                                  {" "}
                                  <strong>Bank Beneficiary Name.: </strong>{" "}
                                  {
                                    value?.source_deposit_instructions
                                      ?.bank_beneficiary_name
                                  }{" "}
                                </li>
                              </>
                            ) : (
                              <>
                                <li>
                                  {" "}
                                  <strong>Transfer From: </strong>
                                  <span className="me-1">
                                    {value?.source_deposit_instructions?.from_address?.substring(
                                      0,
                                      5
                                    ) +
                                      "..." +
                                      value?.source_deposit_instructions?.from_address?.substring(
                                        value?.source_deposit_instructions
                                          ?.from_address?.length - 5
                                      )}
                                  </span>
                                  <FaCopy
                                    onClick={() =>
                                      handleCopy(
                                        value?.source_deposit_instructions
                                          ?.from_address
                                      )
                                    }
                                    style={{ cursor: "pointer" }}
                                  />
                                </li>
                                <li>
                                  {" "}
                                  <strong>Transfer to: </strong>
                                  <span className="me-1">
                                    {value?.source_deposit_instructions?.to_address?.substring(
                                      0,
                                      5
                                    ) +
                                      "..." +
                                      value?.source_deposit_instructions?.to_address?.substring(
                                        value?.source_deposit_instructions
                                          ?.to_address?.length - 5
                                      )}
                                  </span>
                                  <FaCopy
                                    onClick={() =>
                                      handleCopy(
                                        value?.source_deposit_instructions
                                          ?.to_address
                                      )
                                    }
                                    style={{ cursor: "pointer" }}
                                  />
                                </li>
                              </>
                            )}
                          </ul>
                        </td>
                        <td>
                          <span>
                            {" "}
                            ${value?.source_deposit_instructions?.amount}{" "}
                          </span>
                        </td>
                        <td>
                          <ul>
                            <li>
                              {" "}
                              <strong>Currency: </strong>{" "}
                              {value?.destination?.currency?.toUpperCase()}{" "}
                            </li>
                            {value?.source_deposit_instructions?.bank_name ? (
                              <>
                                <li>
                                  {" "}
                                  <strong>Memo/Message: </strong>{" "}
                                  {value?.source_deposit_instructions?.deposit_message?.toUpperCase()}{" "}
                                </li>
                                <li>
                                  {" "}
                                  <strong>Coin: </strong>{" "}
                                  {value?.destination?.payment_rail?.toUpperCase()}{" "}
                                </li>
                                <li>
                                  {" "}
                                  <strong>Address: </strong>
                                  <span className="me-1">
                                    {value?.destination?.to_address?.substring(
                                      0,
                                      5
                                    ) +
                                      "..." +
                                      value?.destination?.to_address?.substring(
                                        value?.destination?.to_address?.length -
                                        5
                                      )}
                                  </span>
                                  <FaCopy
                                    onClick={() =>
                                      handleCopy(value?.destination?.to_address)
                                    }
                                    style={{ cursor: "pointer" }}
                                  />
                                </li>
                              </>
                            ) : (
                              ""
                            )}
                          </ul>
                        </td>
                        <td>
                          <span>
                            {" "}
                            {value?.state.replace("_", " ").toUpperCase()}{" "}
                          </span>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={5}>Record Not Found!</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
      </Modal>

      {/* List of Bank Account of User  */}
      <Modal
        size="lg"
        show={showBankList}
        onHide={() => setShowBankList(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <h6>List of Bank Accounts</h6>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Table striped bordered hover className="modal-font">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Bank Name</th>
                  <th>Owner Name</th>
                  <th>Account No.</th>
                  <th>Created On</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {listAccounts && listAccounts.length > 0 ? (
                  listAccounts.map((value, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{value?.bank_name}</td>
                        <td>{value?.account_owner_name}</td>
                        <td>XXXXXX{value?.last_4}</td>
                        <td>{dateFormat(value?.created_at)}</td>
                        <td>{value?.active ? "Active" : "Not-Active"}</td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={6}>Record Not Found!</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
      </Modal>

      {/* Receive USDC Modal */}
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showReceive}
        animation={false}
      >
        <Modal.Body>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <span
                style={{ cursor: "pointer" }}
                className="back-btn fw-500 text-black "
              // onClick={() => setShowReceive(false)}
              >
                {/* <i className="pe-2 align-middle">
              <FaCircleChevronLeft />
            </i> */}
                Receive USDC
              </span>
            </div>
            <div>
              <span
                onClick={() => setShowReceive(false)}
                style={{ cursor: "pointer", fontSize: "22px" }}
              >
                <IoCloseSharp />
              </span>
            </div>
          </div>

          <div className="px-2 text-center my-4">
            <p className="mt-2 mb-4">
              User-Name :{" "}
              <span className="fw-600 text-black">{userInfo?.userName} </span>
              {/* <i className="text-black">
                <FaCopy />
              </i> */}
            </p>
            <p className="mt-2 mb-4">
              Wallet Address :{" "}
              <span className="fw-600 text-black">
                {address?.toString()?.slice(0, 6)}...
                {address?.toString()?.slice(36, 42)}{" "}
              </span>
              <i
                className="text-black"
                onClick={() => {
                  navigator.clipboard.writeText(address);
                  fireToast("success", "Copied!");
                }}
              >
                <FaCopy />
              </i>
            </p>
            <div>
              <img
                src={`https://chart.apis.google.com/chart?cht=qr&chs=450x450&chl=${address}`}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Send USDC to User Wallet */}
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showSend}
        animation={false}
        onHide={() => setShowSend(false)}
      >
        <Modal.Body>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <span
                className="back-btn fw-500 text-black"
              // onClick={() => setShowSend(false)}
              >
                {/* <i className="pe-2 align-middle">
              <FaCircleChevronLeft />
            </i> */}
                Send USDC
              </span>
            </div>
            <div>
              <span
                onClick={() => {
                  setShowSend(false);
                  SetUserFullName("");
                }}
                style={{ cursor: "pointer", fontSize: "22px" }}
              >
                <IoCloseSharp />
              </span>
            </div>
          </div>

          <div className="dashboard-centerd">
            <div className="row g-0">
              <div className="col-xl-12 col-xxl-12  pt-4  pb-5">
                <div className="">
                  <Tabs
                    defaultActiveKey="platform"
                    id="sendusdc_tabs"
                    className="marketplace-tabs border-0 mb-4"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Tab eventKey="platform" title="Solos Platform">
                      <div className="px-2 my-4">
                        <Formik
                          initialValues={{
                            userName: "",
                            walletAddress: "",
                            // redToken: "",
                            accountType: "Primary",
                          }}
                          validationSchema={SignupSchema}
                          onSubmit={(values, actions) => {
                            setTimeout(() => {
                              onFormSubmit(values, actions);
                              actions.setSubmitting(false);
                            }, 500);
                          }}
                        >
                          {({
                            handleSubmit,
                            errors,
                            touched,
                            setFieldValue,
                          }) => (
                            <Form
                              onSubmit={handleSubmit}
                              className="row user-form"
                            >
                              <Form.Group
                                className="col-12 mb-3"
                                controlId="formBasicEmail"
                              >
                                <Form.Label>Enter User Name</Form.Label>
                                <Field
                                  onChange={(e) => {
                                    setFieldValue("userName", e.target.value);
                                    handleSearch(e.target.value);
                                  }}
                                  autoComplete="off"
                                  type="text"
                                  placeholder="Enter User Name"
                                  name="userName"
                                  className="form-ctl"
                                />
                                <h6 className="text-end mb-0 mt-1">
                                  {userFullName}
                                </h6>
                                {search1 == "" ? (
                                  ""
                                ) : (
                                  <div className="customSearch">
                                    {userData.length == 0 ? (
                                      <div> No Data Found. </div>
                                    ) : (
                                      userData?.map((item, i) => (
                                        <div
                                          onClick={() => {
                                            setFieldValue(
                                              "walletAddress",
                                              item?.walletAddress
                                            );
                                            setFieldValue(
                                              "userName",
                                              item?.userName
                                            );
                                            SetUserFullName(
                                              item?.name + " " + item?.lastname
                                            );
                                            SetSearch("");
                                          }}
                                          className="items"
                                          key={i}
                                        >
                                          <div
                                            style={{
                                              padding: "5px 5px",
                                              border: "1px solid #2c29294d",
                                            }}
                                          >
                                            {item?.userName}
                                            {" - "}
                                            {item?.name + " " + item?.lastname}
                                          </div>
                                        </div>
                                      ))
                                    )}
                                  </div>
                                )}
                                {errors.userName && touched.userName ? (
                                  <div className="text-danger">
                                    {errors.userName}
                                  </div>
                                ) : null}
                              </Form.Group>
                              <Form.Group className="col-12 mb-4" controlId="">
                                <Form.Label>Enter Wallet Address</Form.Label>
                                <Field
                                  type="text"
                                  placeholder="Enter Wallet Address"
                                  name="walletAddress"
                                  className="form-ctl"
                                />
                                {errors.walletAddress &&
                                  touched.walletAddress ? (
                                  <div className="text-danger">
                                    {errors.walletAddress}
                                  </div>
                                ) : null}
                              </Form.Group>

                              <Form.Group className="col-12 mb-4" controlId="">
                                <Form.Label>Enter USDC Amount</Form.Label>
                                <Form.Control
                                  type="number"
                                  placeholder="Enter amount"
                                  className="form-ctl"
                                  value={amount}
                                  onChange={(e) => setAmount(e.target.value)}
                                />
                                {/* {errors.redToken && touched.redToken ? (
                                  <div className="text-danger">
                                    {errors.redToken}
                                  </div>
                                ) : null} */}
                              </Form.Group>

                              <div className="col-12">
                                {loader ? (
                                  <Button
                                    // disabled={loader}
                                    className="w-100 common-btn"
                                    variant="primary"
                                  >
                                    <span className="typeWriter">
                                      processing<span> . . . . .</span>
                                    </span>
                                  </Button>
                                ) : (
                                  <Button
                                    // disabled={loader}
                                    className="w-100 common-btn"
                                    variant="primary"
                                    type="submit"
                                  >
                                    Send
                                  </Button>
                                )}
                              </div>
                            </Form>
                          )}
                        </Formik>
                      </div>
                    </Tab>
                    <Tab eventKey="outsideplatform" title="Outside Solos">
                      <div className="px-2 my-4">
                        <Formik
                          initialValues={{
                            userName: "",
                            walletAddress: "",
                            // redToken: "",
                            accountType: "Primary",
                          }}
                          validationSchema={SignupSchema}
                          onSubmit={(values, actions) => {
                            setTimeout(() => {
                              onFormSubmit(values, actions);
                              actions.setSubmitting(false);
                            }, 500);
                          }}
                        >
                          {({
                            handleSubmit,
                            errors,
                            touched,
                            setFieldValue,
                          }) => (
                            <Form
                              onSubmit={handleSubmit}
                              className="row user-form"
                            >
                              <Form.Group className="col-12 mb-4" controlId="">
                                <Form.Label>Enter Wallet Address</Form.Label>
                                <Field
                                  type="text"
                                  placeholder="Enter Wallet Address"
                                  name="walletAddress"
                                  className="form-ctl"
                                />
                                {errors.walletAddress &&
                                  touched.walletAddress ? (
                                  <div className="text-danger">
                                    {errors.walletAddress}
                                  </div>
                                ) : null}
                              </Form.Group>

                              <Form.Group className="col-12 mb-4" controlId="">
                                <Form.Label>Enter USDC Amount</Form.Label>
                                <Form.Control
                                  type="number"
                                  placeholder="Enter amount"
                                  className="form-ctl"
                                  value={amount}
                                  onChange={(e) => setAmount(e.target.value)}
                                />
                                {/* {errors.redToken && touched.redToken ? (
                                  <div className="text-danger">
                                    {errors.redToken}
                                  </div>
                                ) : null} */}
                              </Form.Group>

                              <div className="col-12">
                                {loader ? (
                                  <Button
                                    // disabled={loader}
                                    className="w-100 common-btn"
                                    variant="primary"
                                  >
                                    <span className="typeWriter">
                                      processing<span> . . . . .</span>
                                    </span>
                                  </Button>
                                ) : (
                                  <Button
                                    // disabled={loader}
                                    className="w-100 common-btn"
                                    variant="primary"
                                    type="submit"
                                  >
                                    Send
                                  </Button>
                                )}
                              </div>
                            </Form>
                          )}
                        </Formik>
                      </div>
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <PinConfirm
        setShow={setShowSend}
        formData={formData}
        showPinModal={showPinModal}
        handleSubmit={sendCustomAssets}
        handleClosePinModal={handleClosePinModal}
      />
    </>
  );
};

export default BooqBalance;
