import React, { useEffect, useState } from "react";
import "./Balancecard.css";
import { getMyInfo } from "../../services/propertyServices";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { fireToast } from "../../common/Toster";
import { FaCopy } from "react-icons/fa6";
const Balancecard = ({ balance, address }) => {
  const [show, setshow] = useState(false);
  const [data, setData] = useState();
  useEffect(() => {
    getMyInfo()
      .then((res) => {
        setData(res?.data);
      })
      .catch((err) => {
        console.log(err, "err getting data");
      });
  }, []);

  const handleCopy = (value) => {
    navigator.clipboard.writeText(value);
    fireToast("success", "Copied!");
  };
  return (
    <>
      <div className="balance-card mx-auto p-3">
        <div className="token-head d-flex align-items-center justify-content-between">
          <div>
            <span className="text-white" style={{ fontSize: "18px" }}>
              Hello,
            </span>
            <h6 className=" text-white" style={{ fontSize: "16px" }}>
              {data?.name} {data?.name && data?.lastname}
            </h6>
          </div>
          <div className="text-end">
            <span className="text-white" style={{ fontSize: "18px" }}>
              Wallet Address
            </span>
            <h6 className=" text-white" style={{ fontSize: "16px" }}>
              {address?.slice(0, 3)}...{address?.slice(39, 42)}&nbsp;
              <span
                style={{ cursor: "pointer" }}
                onClick={() => handleCopy(address)}
              >
                <FaCopy />
              </span>
            </h6>
          </div>
        </div>
        {/* <div className="mt-4 text-center">
            <p className="f-14 fw-500 text-white mb-0">Total balance</p>
            <p className="f-24 fw-60 text-white mb-4">{!show ? '******' : `$ ${formatCompactNumber(((Number(balance)/Number(redUsd)) +( Number(balanceSr)/Number(srUsd)) )) }`} { show ? <span onClick={()=>setshow(!show)} style={{cursor:"pointer"}}> < AiFillEyeInvisible style={{marginBottom:'5px'}} /></span> 
            // <p className="f-24 fw-60 text-white mb-4">{!show ? '******' : `$ ${formatCompactNumber((Number(balance))+( Number(balanceSr))) }`} { show ? <span onClick={()=>setshow(!show)} style={{cursor:"pointer"}}> < AiFillEyeInvisible style={{marginBottom:'5px'}} /></span> 

            : <span style={{cursor:"pointer"}} onClick={()=>setshow(!show)}> <AiFillEye style={{marginBottom:'5px'}}  /></span>}</p>
        </div> */}
        <div className="mt-4 text-center">
          <p className="f-18 fw-500 text-white mb-0">Total Worth (in USD)</p>

          <p className="f-24 fw-60 text-white mb-4">
            {!show
              ? "******"
              : `$ ${Number(
                  Number(balance)
                    .toString()
                    .match(/^-?\d+(?:.\d{0,2})?/)[0]
                ).toLocaleString()}`}{" "}
            {show ? (
              <span
                onClick={() => setshow(!show)}
                style={{ cursor: "pointer" }}
              >
                {" "}
                <AiFillEyeInvisible style={{ marginBottom: "7px" }} />
              </span>
            ) : (
              <span
                style={{ cursor: "pointer" }}
                onClick={() => setshow(!show)}
              >
                {" "}
                <AiFillEye style={{ marginBottom: "12px" }} />
              </span>
            )}
          </p>
        </div>
      </div>
    </>
  );
};

export default Balancecard;
