import React from "react";
import "./RedToken.css";
import Solos from "../../images/newcardlogo.svg";
import Send from "../../components/Modals/Send";
import Receive from "../Modals/Receive";
import Buy from "../Modals/Buy";
import Sell from "../Modals/Sell";

const RedToken = ({ prop, redTokenData, getTokenBalance }) => {
  const RedToken = redTokenData;

  return (
    <>
      <div
        className="token-card red-token d-flex flex-column justify-content-between position-relative"
        style={{ cursor: "pointer" }}
      >
        <div className="token-head d-flex align-items-center justify-content-between">
          <div>
            <span className="text-white fw-400 scard-title">RED Tokens</span>
            <h6 className="fs-24 text-white text-start ps-1">
              {RedToken
                ? Number(
                  Number(RedToken?.balance)
                    .toString()
                    .match(/^-?\d+(?:.\d{0,2})?/)[0]
                ).toLocaleString()
                : 0}
            </h6>
            <span className="text-white fw-400 scard-sub-title">
              Value in USD
            </span>
            <h6 className="fs-24 text-white text-start ps-1">
              $
              {RedToken
                ? Number(
                  Number(+RedToken?.balance * +RedToken?.conversionrate)
                    .toString()
                    .match(/^-?\d+(?:.\d{0,2})?/)[0]
                ).toLocaleString()
                : 0}
            </h6>
          </div>
        </div>
        <div className="token-bottom">
          <div
            className="row d-flex align-items-center text-center"
          >
            <span className="col-3 d-flex justify-content-center text-center fs-8 mb-0 text-white fw-500 cursor-pointer">
              {" "}
              <Send getWallet={getTokenBalance} />
            </span>
            <span className="col-3 d-flex justify-content-center text-center fs-8 mb-0 text-white fw-500 cursor-pointer">
              <Receive title={'RED'} />
            </span>
            <span className="col-3 d-flex justify-content-center text-center fs-8 mb-0 text-white fw-500 cursor-pointer">
              <Buy />
            </span>
            <span className="col-3 d-flex justify-content-center text-center fs-8 mb-0 text-white fw-500 cursor-pointer">
              <Sell />
            </span>
          </div>
        </div>
        <div
          className="text-center"
          style={{ position: "absolute", top: "20px", right: "20px" }}
        >
          <img src={Solos} alt="" height={24} />
        </div>
      </div>
    </>
  );
};

export default RedToken;
