const Protectedpaths = {
  getDashboard: () => "/dashboard",
  getAccounts: () => "/my-holdings",
  getTransactions: () => "/transactions",
  getPossessions: () => "/possessions",
  getTVTs: () => "/tvt",
  getMarketplace: () => "/marketplace",
  getViewAllNfts: () => "/all-nfts",
  getViewAllSellNfts: () => "/all-user-marketplace-nfts",
  getViewAllSellTvts: () => "/all-user-marketplace-tvts",
  getViewAllSellFreds: () => "/all-user-marketplace-freds",
  getViewAllSellSfreds: () => "/all-user-marketplace-sfreds",
  getViewAllFreds: () => "/all-freds",
  getViewAllTvts: () => "/all-tvts",
  getViewAllSfreds: () => "/all-sfreds",
  getViewAllBuyFreds: () => "/all-buy-freds",
  getViewAllBuyNfts: () => "/all-buy-nfts",
  getViewAllBuyTvts: () => "/all-buy-tvts",
  getViewAllBuySfreds: () => "/all-buy-sfreds",
  getNftDetails: () => "/nft-details",
  getSellNftDetails: () => "/user-marketplace-nft-details",
  getSellTvtDetails: () => "/user-marketplace-tvt-details",
  getSellFredDetails: () => "/user-marketplace-fred-details",
  getSellSrDetails: () => "/user-marketplace-sr-details",
  getSellSFredDetails: () => "/user-marketplace-sfred-details",
  getFredDetails: () => "/fred-details",
  getTvtDetails: () => "/tvt-details",
  getSuperFredDetails: () => "/super-fred-details",
  // getSupport: () => "/support",
  getSettings: () => "/Settings",
  getRecoverWallet: () => "/recover-wallet",
  getLease: () => "/property-request",
  getPossessionsDetail: () => "/possession-details",
  getPossessionsList: () => "/possession-list",
};

// public routes --------------------------------
const Publicpaths = {
  getRoot: () => "/",
  getLogin: () => "/login",
  getRegister: () => "/register",
  getForgotEmail: () => "/forgotEmail",
  getOtp: () => "/otp",
  getForgotOtp: () => "/forgotOtp",
  getForgotPassword: () => "/forgot-password",
  getKyc: () => "/kyc",
  getOtpVerified: () => "/otp-verified",
};

export { Protectedpaths, Publicpaths };
