import { Protectedpaths } from "./paths";
import Dash from "../images/icon1.svg";
import accounts from "../images/icon2.svg";
import Possessions from "../images/icon3.svg";
import Marketplace from "../images/icon3.svg";
import TransactionIcon from "../images/transactionIcon.svg";
import MarketPlaceIcon from "../images/marketplaceIcon.svg";
import PossessionsIcon from "../images/possesstionIcon.svg";
import PropertyRequestIcon from "../images/propertyRequestIcon.svg";
import RecoverWalletIcon from "../images/recoverWalletIcon.svg";
import DashboardIcon from "../images/dashboardIcon.svg";
import AccountsIcon from "../images/accountsIcon.svg";
import SettingsIcon from "../images/settingsIcon.svg";
import CommunicationIcon from "../images/communicationIcon.svg";
import Support from "../images/icon4.svg";
import { RiRefreshFill } from "react-icons/ri";
import Setting from "../images/icon5.svg";
const Sidebarpaths = [
  {
    path: Protectedpaths.getDashboard(),
    title: "Dashboard",
    icon: DashboardIcon,
  },
  {
    path: Protectedpaths.getAccounts(),
    title: "My Holdings",
    icon: AccountsIcon,
  },
  {
    path: Protectedpaths.getTransactions(),
    title: "Transactions",
    icon: TransactionIcon,
  },
  {
    path: Protectedpaths.getPossessions(),
    title: "Possessions",
    icon: PossessionsIcon,
  },
  {
    path: Protectedpaths.getMarketplace(),
    title: "Marketplaces",
    icon: MarketPlaceIcon,
  },
  // {
  //   path: Protectedpaths.getSupport(),
  //   title: "Communications",
  //   icon: CommunicationIcon,
  // },
  {
    path: Protectedpaths.getLease(),
    title: "Property Request",
    icon: PropertyRequestIcon,
  },
  {
    path: Protectedpaths.getSettings(),
    title: "Settings",
    icon: SettingsIcon,
  },
  {
    path: Protectedpaths.getRecoverWallet(),
    title: "Recover Wallet",
    icon: RecoverWalletIcon,
  },
];

export default Sidebarpaths;
