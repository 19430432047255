import React, { useContext, useState } from "react";
import "./Modal.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import PinConfirm from "./PinConfirm";
import Buyicon from "../../images/buy.svg";
import { IoCloseSharp } from "react-icons/io5";
import configURl from "../../runtime.config";
import { ConversionContext } from "../../Context/context";
import SRABI from "../../Abis/SR_ABi.json";
import { buySolosReward } from "../../services/propertyServices";
import {
  fireSuccessToast,
  fireToast,
  maintenancemodePopup,
} from "../../common/Toster";
import { toast } from "react-toastify";
import { useAddress, useContract, useSigner } from "@thirdweb-dev/react";
import { ethers } from "ethers";

const BuySr = ({ getBalance, balance }) => {
  const { contract } = useContract(configURl.redNewAddress);
  const signer = useSigner();
  const address = useAddress();
  const [loader, setLoader] = useState(false);
  const contextData = useContext(ConversionContext);
  const [show, setShow] = useState(false);
  const isPinActive = contextData?.userData?.isMpinActive;
  const [redToken, setRedToken] = useState(0);
  const handleClosePinModal = () => {
    setShowPinModal(false);
  };
  const [srToken, setSRToken] = useState(0);
  const [conversionRate, setConversionRate] = useState(1);
  const handleClose = () => setShow(false);
  const [showPinModal, setShowPinModal] = useState(false);

  const userData = contextData?.userData;

  const handleShow = async () => {
    setConversionRate(1);
    setShow(true);
  };

  const onBuySr = () => {
    (isPinActive
      ? () => {
        setShow(false);
        setShowPinModal(true);
      }
      : () => {
        buyFunction();
      })();
  };

  async function buyFunction() {
    if (redToken <= 0) {
      fireToast("error", "please enter amount...");
    } else {
      try {
        setLoader(true);
        const fixedAmount = Number(redToken).toFixed(5).toString();
        const fixedSrAmount = Number(srToken).toFixed(5).toString();
        const maticBalance = ethers.utils.formatEther(
          await signer.getBalance()
        );
        // const gasPrice = await signer.getGasPrice();
        const gasPrice = ethers.utils.parseUnits("500", "gwei");
        // const estimatedGasPrice = ethers.utils.formatEther(gasPrice);
        if (+maticBalance < configURl.minMaticLimit) {
          fireToast("error", "Insufficient matic funds for gas price !");
          setLoader(false);
          return;
        }
        let balance = await contract.erc20.balanceOf(address);
        if (+balance.displayValue < +fixedAmount) {
          fireToast("error", "Transfer amount exceeds balance !");
          setLoader(false);
          return;
        }
        const redContract = new ethers.Contract(
          configURl.redNewAddress,
          contract.abi,
          signer
        );
        const redDecimals = await redContract.decimals();
        const approvedAmount = await redContract.allowance(
          address,
          configURl.SR_Address
        );
        let formattedApprovedAmount = ethers.utils.formatUnits(
          approvedAmount,
          redDecimals
        );
        console.log(formattedApprovedAmount, "formattedApprovedAmount");
        if (+formattedApprovedAmount < +fixedAmount) {
          console.log("ask for approve");
          const approveTrnx = await redContract.approve(
            configURl.SR_Address,
            "1000000000000000000000000",
            {
              maxFeePerGas: gasPrice,
              maxPriorityFeePerGas: gasPrice,
              gasLimit: 600000,
            }
          );
          console.log(approveTrnx, "approving...");
          const approved = await approveTrnx.wait();
          console.log(approved, "approved");
        }
        const contractInstance = new ethers.Contract(
          configURl.SR_Address,
          SRABI,
          signer
        );
        const decimals = await contractInstance.decimals();
        const hexQuantity = ethers.utils.parseUnits(fixedSrAmount, decimals);
        const response = await contractInstance.buySR(hexQuantity, {
          maxFeePerGas: gasPrice,
          maxPriorityFeePerGas: gasPrice,
          gasLimit: 600000,
        });
        if (response) {
          const requestData = {
            accountType: "PRIMARY",
            hashId: response.hash,
            totalRedToken: fixedAmount,
            solosReward: fixedSrAmount,
            walletAddress: address,
          };
          console.log(response, "buySR trnx.");
          await buySolosReward(requestData).then(
            (response) => {
              setLoader(false);
              console.log(response);
              handleClose();
              getBalance();
              fireSuccessToast("Successfully Done!");
            },
            (error) => {
              setLoader(false);
              console.log(error);
              handleClose();
              toast.error("Transaction Failed!");
            }
          );
        }
      } catch (error) {
        setLoader(false);
        handleClose();
        toast.error("Something went wrong !");
        console.log(error);
      }
    }
  }

  return (
    <>
      <Button
        className="bg-transparent border-0 p-0"
        variant="primary"
        onClick={() => {
          if (userData?.maintenanceMode) {
            maintenancemodePopup();
          } else {
            handleShow();
          }
        }}
      >
        <span className="token-btn d-flex align-items-center justify-content-center mx-auto mb-1">
          <img src={Buyicon} alt="" />
        </span>
        <p className="scard-btext">Buy</p>
      </Button>

      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
        animation={false}
      >
        <Modal.Body>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <Link className="back-btn fw-500 text-black">Buy SR</Link>
            </div>
            <div>
              <span
                onClick={() => setShow(false)}
                style={{ cursor: "pointer", fontSize: "22px" }}
              >
                <IoCloseSharp />
              </span>
            </div>
          </div>

          <div className="px-2 my-4">
            <Form className="row user-form">
              <Form.Group className="col-12 mb-4" controlId="">
                <Form.Label>How many SR you want to buy?</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="0"
                  value={srToken}
                  onChange={(e) => {
                    setSRToken(e.target.value);
                    setRedToken(e.target.value * conversionRate);
                  }}
                />
              </Form.Group>
              <Form.Group className="col-12 mb-3" controlId="formBasicEmail">
                <Form.Label>Enter Amount in RED</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="0"
                  onChange={(e) => {
                    setSRToken(e.target.value / conversionRate);
                    setRedToken(e.target.value);
                  }}
                  value={redToken}
                />
              </Form.Group>

              {/* <Form.Group className="col-12 mb-4" controlId="">
                <Form.Label>Enter Wallet Address </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Wallet address.."
                  value={walletAddress}
                  onChange={(e) => {
                    setWalletAddress(e.target.value);
                  }}
                />
              </Form.Group> */}

              <div className="col-12">
                {loader ? (
                  <Button className="w-100 common-btn" variant="primary">
                    <span className="typeWriter">
                      processing<span> . . . . .</span>
                    </span>
                  </Button>
                ) : (
                  <Button
                    className="w-100 common-btn"
                    variant="primary"
                    disabled={redToken <= 0 || srToken <= 0}
                    onClick={onBuySr}
                  >
                    Buy
                  </Button>
                )}
              </div>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
      <PinConfirm
        setShow={setShow}
        showPinModal={showPinModal}
        handleSubmit={buyFunction}
        handleClosePinModal={handleClosePinModal}
      />
    </>
  );
};

export default BuySr;
