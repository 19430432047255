import React, { createContext, useState, useEffect } from "react";
import { getConversionRate } from "../services/NftServices";
import { getUserProfile } from "../services/userService";

export const ConversionContext = createContext();
export default function Conversions(props) {
  const [showKyc, setShowKyc] = useState(false);
  const [conversionRate, setConversionRate] = useState("");
  const [adminWalletAddress, setAdminWalletAddress] = useState("");
  const [adminReceiveWalletAddress, setAdminReceiveWalletAddress] =
    useState("");
  // const [adminPrivateKey, setAdminPrivateKey] = useState("");
  const [userId, setUserId] = useState("");
  const [userData, setUserData] = useState({});

  const getRedConversionRate = async () => {
    try {
      const response = await getConversionRate();
      setConversionRate(response?.data?.conversionrate);
      setAdminWalletAddress(response?.data?.adminWalletAddress);
      setAdminReceiveWalletAddress(response?.data?.receivewalletaddress);
      // setAdminPrivateKey(response?.data?.privatekey);
    } catch (error) {
      console.error("Error fetching nft data:", error);
    }
  };

  const getUserData = async () => {
    try {
      const response = await getUserProfile();
      setUserId(response?.data?._id);
      setUserData(response?.data);
    } catch (error) {
      console.error("Error fetching nft data:", error);
    }
  };

  useEffect(() => {
    getRedConversionRate();
    getUserData();
  }, []);

  let data = {
    conversionRate,
    adminWalletAddress,
    adminReceiveWalletAddress,
    // adminPrivateKey,
    userId,
    userData,
    getUserData,
    showKyc,
    setShowKyc
  };

  return (
    <ConversionContext.Provider value={data}>
      {props.children}
    </ConversionContext.Provider>
  );
}
